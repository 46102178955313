.container {
  width: 100%;
  height: 80px !important;
  padding: 12px;
  border-radius: 20px !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between;
}

.container .container-1 {
  height: 100%;
  border-radius: 12px;
  aspect-ratio: 1;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.container .container-2 {
  width: 70%;
  height: 100%;
  border-radius: 12px;
}

.container .container-2 .container-text {
  display: block;
  width: 100%;
  font-size: 18px;
  font-weight: bold;
}
