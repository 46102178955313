.fontFamily-inter {
  font-family: "Inter", sans-serif !important;
}

.fontFamily-inter-bold {
  font-family: "Inter", sans-serif !important;
  font-weight: bold !important;
}

.color-primary {
  color: #ff8000 !important;
}

.color-secondary {
  color: #767676 !important;
}

.color-dark {
  color: #000 !important;
}

.color-light {
  color: #fff !important;
}
.btn-primary {
  background-color: #ff8000 !important;
  border-color: #ff8000 !important;
}

.btn-primary:hover {
  background-color: rgba(255, 128, 0, 0.8) !important;
  border-color: rgba(255, 128, 0, 0.8) !important;
}
