.main-frame-container {
  /* border: 1px solid red; */
  background-image: url("../../assets/img/bg_mf.png");
  background-position: center;
  background-size: cover;
}
.mf-container-left {
  margin-top: 120px;
  margin-left: 60px;
  padding: 12px;
  /* border: 1px solid red; */
}

.mf-container-right {
  /* border: 1px solid red; */
  margin-bottom: 60px;
}

.img-container {
  margin-right: 80px;
  margin-top: 80px;
  transform: scaleX(-1);
  text-align: center;
}
.img-container img {
  /* width: 400px; */
}

@media only screen and (max-width: 600px) {
  .mf-container-left {
    margin-top: 60px;
    margin-left: 0;
    text-align: center;
  }
  .heading-container {
    font-size: 12px !important;
  }
  .btn-container {
    margin: auto;
  }
  .img-container {
    margin-top: 40px;
    margin-right: 0;
  }
  .img-container img {
    width: 260px;
  }
}
